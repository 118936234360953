export const price={
    9:10,
    18:20,
    27:25,
    36:60,
    45:100,
    54:150,
    63:250,
    72:400,
    81:650,
    90:800,
    100:1000
    
}
export const unity ="DOG token"